export function NewWindowIcon(props: { className?: string }): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6109 2.52L6.51913 6.61122C6.27905 6.85185 6.27905 7.24176 6.51913 7.48239C6.7592 7.72247 7.15022 7.72303 7.3903 7.48239L11.4804 3.39285V4.76058C11.4804 5.06902 11.7309 5.32004 12.0404 5.32004C12.3488 5.32004 12.6004 5.06956 12.6004 4.76058V1.95946C12.6004 1.80524 12.5375 1.66579 12.4363 1.56408C12.3352 1.4629 12.1952 1.40001 12.041 1.40001H9.23983C8.9314 1.40001 8.68038 1.65048 8.68038 1.96001C8.68038 2.26844 8.93085 2.52001 9.23983 2.52001L10.6109 2.52ZM12.6004 8.12V5.45776V11.2043C12.6004 11.9749 12.0448 12.6 11.359 12.6H2.6418C1.95602 12.6 1.40039 11.9738 1.40039 11.2043V2.79566C1.40039 2.02511 1.95602 1.40002 2.6418 1.40002H8.61966H5.88042C6.18994 1.40002 6.44042 1.6505 6.44042 1.96002C6.44042 2.26955 6.18994 2.52002 5.88042 2.52002H2.8392C2.66092 2.52002 2.52038 2.68737 2.52038 2.89354V11.1065C2.52038 11.3088 2.66311 11.48 2.8392 11.48H11.1615C11.3398 11.48 11.4803 11.3127 11.4803 11.1065V8.12002C11.4803 7.8105 11.7308 7.56002 12.0403 7.56002C12.3498 7.56002 12.6004 7.81047 12.6004 8.12Z'
      />
    </svg>
  );
}
